<template>
  <section class="app__terms">
    <div class="app__terms--right">
      <a
        class="app__legal--info__left--btn active-menu"
        href="#privacy_introduction"
        id="introductions"
      >
        Introduction
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#privacy_definition"
        id="definitions"
      >
        Definition & Interpretation 
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#privacy_grants"
        id="grant"
      >
        Grants & scope of license
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#privacy_restriction"
        id="restrictions"
      >
        Acceptable use of restriction
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#privacy_interest"
        id="interests"
      >
        Interest & fees
      </a>
    </div>

    <div class="app__terms--left">
      <div class="app__terms--left__container" id="privacy_introduction">
        <h4>Introduction</h4>

        <p>
          <span>TERMS AND CONDITIONS OF HIRE-PURCHASE AGREEMENT</span><br />

          These Terms & Conditions (the “Agreement”) shall form the basis of the hire-purchase arrangement provided by
          PowerNow (the “Lessor).  In this Agreement, “Lessee” refers to the party that accepts this Agreement. 
        </p>
      </div>

      <div class="app__terms--left__container" id="privacy_definition">
        <h4>Definition & Interpretation</h4>

        <p>
          <span>1. Interpretation</span><br />
          <span>Unless otherwise stipulated in this Agreement, the terms used in this Agreement shall bear the following meanings:</span><br />
          1. <span>Asset:</span> the item(s) listed in Schedule 2, all substitutions, replacements or renewals of such Asset and all related accessories, manuals and instructions provided for it.<br />
          2. <span>Business Day:</span> a day, other than a Saturday, Sunday or public holiday in Nigeria, when banks in Lagos are open for business.<br />
          3. <span>Business Hours:</span> the period from 9.00 am to 5.00 pm on any Business Day.<br />
          4. <span>Commencement Date:</span> the commencement date which is set out in the Payment Schedule. <br />
          5. <span>Delivery:</span> the transfer of physical possession of the Asset to the Lessee at the Site.<br />
          6. <span>Delivery Date:</span> the delivery date which is set out in the Payment Schedule<br />
          7. <span>Deposit:</span> the deposit amount set out in the Payment Schedule.<br />
          8. <span>Lease Period:</span> the period set forth in Schedule 1.<br />
          9. <span>Payment Schedule:</span> Schedule 1, which sets out the sums payable under this agreement.<br />
          10. <span>Purchase Option:</span> the Lessee's option to purchase the Asset as more fully described in clause 8.<br />
          11. <span>Purchase Option Price:</span> the price of the Purchase Option as set out in the Payment Schedule.<br />
          12. <span>Rental Payments:</span> the payments made by or on behalf of Lessee for hire of the Asset.<br />
          13. <span>Rental Period:</span> the period of hire as set out in clause 3.<br />
          14. <span>Risk Period:</span> the period during which the Asset is at the sole of the risk of the Lessee as set out in clause 6.2.<br />
          15. <span>Site:</span> the Lessee's premises as set forth in the Payment Schedule. <br />
          16. <span>Total Loss:</span> the Asset is, in the Lessor's reasonable opinion, damaged beyond repair, lost, stolen, seized or confiscated.<br />
          17. <span>VAT:</span> value added tax chargeable in Nigeria.<br />
          1. Clause, Schedule and paragraph headings shall not affect the interpretation of this agreement.<br />
          2. A person includes a natural person, corporate or unincorporated body (whether or not having separate legal personality) [and that person's legal and personal representatives, successors and permitted assigns].<br />
          3. The Schedules form part of this agreement and shall have effect as if set out in full in the body of this agreement and any reference to this agreement includes the schedules. <br />
          4. A reference to a company shall include any company, corporation or other body corporate, wherever and however incorporated or established. <br />
          5. Unless the context otherwise requires, words in the singular shall include the plural and in the plural shall include the singular.<br />
          6. Unless the context otherwise requires, a reference to one gender shall include a reference to the other genders. <br />
          7. Unless expressly provided otherwise in this agreement, a reference to legislation or a legislative provision is a reference to it as amended, extended or re-enacted from time to time.<br />
          8. A reference to writing or written excludes fax but not email.<br />
          9. Any obligation on a party not to do something includes an obligation not to allow that thing to be done.<br />
          10. A reference to this agreement or to any other agreement or document is a reference to this agreement or such other agreement or document, in each case as varied from time to time.<br />
          11. References to clauses and Schedules are to the clauses and Schedules of this agreement and references to paragraphs are to paragraphs of the relevant Schedule.<br />
          12. Any words following the terms including, include, in particular, for example or any similar expression shall be interpreted as illustrative and shall not limit the sense of the words preceding those terms.<br />
        </p>
      </div>

      <div class="app__terms--left__container" id="privacy_grants">
        <h4>Grants & scope of license</h4>

        <p>
          <span>6. Title, risk and insurance</span><br />
          6.1. The Asset shall at all times remain the property of the Lessor, and the Lessee shall have no right,
          title or interest in or to the Asset (save the right to possession and use of the Asset subject to the
          terms and conditions of this agreement) except where the Lessee purchases the Asset pursuant to the Purchase
          Option in clause 8. <br />
          6.2. The risk of loss, theft, damage or destruction of the Asset shall pass to the Lessee on Delivery.
          The Asset shall remain at the sole risk of the Lessee during the Rental Period and any further term
          during which the Asset is in the possession, custody or control of the Lessee (Risk Period) until such
          time as the Asset is redelivered to the Lessor. During the Rental Period and the Risk Period, the Lessee
          shall, at its own expense, obtain and maintain the following insurances:<br />
            (a) insurance of the Asset to a value not less than its full replacement value comprehensively against all
            usual risks of loss, damage or destruction by fire, theft or accident, and such other risks as the Lessor
            may from time to time nominate in writing;
            (b) insurance for such amounts as a prudent owner or operator of the Asset would insure for, or such amount
            as the Lessor may from time to time reasonably require, to cover any third party or public liability risks
            of whatever nature and however arising in connection with the Asset; and
            (c) insurance against such other or further risks relating to the Asset as may be required by law, together
            with such other insurance as the Lessor may from time to time consider reasonably necessary and advise to the
            Lessee in writing.<br />
          6.3. All insurance policies procured by the Lessee shall be endorsed to provide the Lessor with at least
          fourteen (14) Business Days' prior written notice of cancellation or material change (including any
          reduction in coverage or policy amount) and shall on the Lessor's request name the Lessor on the policies
          as a loss payee in relation to any claim relating to the Asset. The Lessee shall be responsible for
          paying any deductibles due on any claims under such insurance policies.<br />
          6.4. If the Lessee fails to effect or maintain any of the insurances required under this agreement, the
          Lessor shall be entitled to effect and maintain the same, pay such premiums as may be necessary for
          that purpose and recover the same as a debt due from the Lessee.<br />
          6.5. The Lessee shall, on demand, supply copies of the relevant insurance policies or other
          insurance confirmation acceptable to the Lessor and proof of premium payment to the Lessor to
          confirm the insurance arrangements.<br />
          6.6. The Lessee shall give immediate written notice to the Lessor in the event of any loss,
          accident or damage to the Asset arising out of or in connection with the Lessee's possession or use of the Asset.<br />
          <span>7. Lessee's responsibilities</span><br />
          7.1. The Lessee shall during the term of this agreement:<br />
              (a) ensure that the Asset is kept and operated in a suitable environment used only for the purposes for which it is designed, and operated in a proper manner in accordance with any operating instructions provided by the Lessor;
              (b) take such steps (including compliance with all safety and usage instructions provided by the Lessor) as may be necessary to ensure, so far as is reasonably practicable, that the Asset is at all times safe and without risk to health when it is being set, used, cleaned or maintained by a person at work;
              (c) maintain at its own expense the Asset in good and substantial repair in order to keep it in as good an operating condition as it was on the Delivery Date (fair wear and tear only excepted) including replacement of worn, damaged and lost parts, and shall make good any damage to the Asset;
              (d) make no alteration to the Asset and shall not remove any existing component (or components) from the Asset [without the prior written consent of the Lessor unless the component (or components) is (or are) replaced immediately (or if removed in the ordinary course of repair and maintenance as soon as practicable) by the same component or by one of a similar make and model or an improved or advanced version of it. Title in all substitutions, replacements, renewals made in or to the Asset shall vest in the Lessor immediately on installation;
              (e) keep the Lessor fully informed of all material matters relating to the Asset;
              (f) keep the Asset at all times at the Site and shall not move or attempt to move any part of the Asset to any other location without the Lessor's prior written consent;
              (g) permit the Lessor or its duly authorized representative to inspect the Asset at all reasonable times and for such purpose to enter on the Site or any premises at which the Asset may be located, and shall grant reasonable access and facilities for such inspection;
              (h) maintain operating and maintenance records of the Asset and make copies of such records readily available to the Lessor, together with such additional information as the Lessor may reasonably require;
              (i) not, without the prior written consent of the Lessor, part with control of (including for the purposes of repair or maintenance), sell or offer for sale, underlet or lend the Asset or allow the creation of any mortgage, charge, lien or other security interest in respect of it;
              (j) not without the prior written consent of the Lessor, attach the Asset to any land or building so as to cause the Asset to become a permanent or immovable fixture on such land or building. If the Asset does become affixed to any land or building then the Asset must be capable of being removed without material injury to such land or building and the Lessee shall repair and make good any damage caused by the affixation or removal of the Asset from any land or building and indemnify the Lessor against all losses, costs or expenses incurred as a result of such affixation or removal;
              (k) not do or permit to be done any act or thing which will or may jeopardise the right, title or interest of the Lessor in the Asset and, where the Asset has become affixed to any land or building, the Lessee must take all necessary steps to ensure that the Lessor may enter such land or building and recover the Asset both during the term of this agreement and for a reasonable period thereafter, including by procuring from any person having an interest in such land or building, a waiver in writing and in favour of the Lessor of any rights such person may have or acquire in the Asset and a right for the Lessor to enter onto such land or building to remove the Asset;
              (l) not suffer or permit the Asset to be confiscated, seized or taken out of its possession or control under any distress, execution or other legal process, but if the Asset is so confiscated, seized or taken, the Lessee shall notify the Lessor and the Lessee shall at its sole expense use its best endeavours to procure an immediate release of the Asset and shall indemnify the Lessor on demand against all losses, costs, charges, damages and expenses [reasonably] incurred as a result of such confiscation;
              (m) not use the Asset for any unlawful purpose;
              (n) ensure that at all times the Asset remains identifiable as being the Lessor's property and wherever possible shall ensure that a visible sign to that effect is attached to the Asset;
              (o) deliver up the Asset at the end of the Rental Period at such address as the Lessor requires, or if necessary allow the Lessor or its representatives access to the Site or any premises where the Asset is located for the purpose of removing the Asset; and
              (p) not do or permit to be done anything which could invalidate the insurances referred to in clause 6.2.<br />
          7.2. The Lessee acknowledges that the Lessor shall not be responsible for any loss of or damage to the Asset arising
          out of or in connection with any negligence, misuse, mishandling of the Asset or otherwise caused by the Lessee or
          its officers, employees, agents and contractors and the Lessee shall indemnify the Lessor in full against all
          liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss
          of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis)
          and all other professional costs and expenses) suffered or incurred by the Lessor arising out of, or in connection
          with any failure by the Lessee to comply with its obligations in this clause 7.<br />
          <span>8. Purchase Option</span><br />
          8.1. The Lessee shall, subject to clause 8.2, have the option, exercisable by not less than twenty
          (20) Business Days' written notice to the Lessor, to purchase the Asset on the last Business Day
          of the Rental Period at the Purchase Option Price.<br />
          8.2. The Purchase Option may be exercised only if:<br />
              (a) all amounts due to the Lessor under this agreement up to the date of exercise of the Purchase Option have been paid in full by the Lessee; and
              (b) the Rental Period has not ended by reason of the Lessor terminating this agreement in accordance with clause 11.1.<br />
          8.3. On completion of the purchase of the Asset under this clause 8, such title to the Asset as the Lessor had 
          on the Commencement Date shall transfer to the Lessee. The Asset shall transfer to the Lessee in the 
          condition and at the location in which it is found on the date of transfer.<br />
          <span>9. Warranty</span><br />
          9.1. The Lessor warrants that the Asset shall substantially conform to its specification (as made available by the Lessor),
          be of satisfactory quality and fit for any purpose held out by the Lessor. The Lessor shall use all reasonable endeavors
          to remedy, free of charge, any material defect in the Asset which manifests itself within the stipulated period on the
          quotation from Delivery, provided that:<br />
              (a) the Lessee notifies the Lessor of any defect in writing within or through the platform provided ten (10) Business Days of the defect occurring;
              (b) the Lessor is permitted to make a full examination of the alleged defect;
              (c) the defect did not materialise as a result of misuse, neglect, alteration, mishandling or unauthorised manipulation by any person other than the Lessor's authorised personnel;
              (d) the defect did not arise out of any information, design or any other assistance supplied or furnished by the Lessee or on its behalf; and
              (e) the defect is directly attributable to defective material, workmanship or design.<br />
          9.2. Insofar as the Asset comprises or contains Asset or components which were not manufactured or produced by the Lessor, 
          the Lessee shall be entitled only to such warranty or other benefit as the Lessor has received from the manufacturer.<br />
          9.3. If the Lessor fails to remedy any material defect in the Asset in accordance with clause 9.1, the Lessor
          shall, at the Lessee's request, accept the return of part or all of the Asset and make an appropriate reduction
          to the Rental Payments payable during the remaining term of the agreement and, if relevant, return any Deposit
          (or any part of it).<br />
        </p>
      </div>

      <div class="app__terms--left__container" id="privacy_restriction">
        <h4>Acceptable use of restriction</h4>

        <p>
          <span>16. Entire agreement</span><br />
          16.1. This agreement constitutes the entire agreement between the parties.<br />
          16.2. Each party acknowledges that in entering into this agreement it does not rely on any statement,
          representation, assurance or warranty (whether made innocently or negligently) that is not set out in
          this agreement. Each party agrees that it shall have no claim for innocent or negligent misrepresentation
          based on any statement in this agreement.<br />
          <span>17. Variation</span><br />
          The Lessor may vary the terms of this Agreement with notice to the Lessee.<br />
          <span>18. No partnership or agency</span><br />
          18.1. Nothing in this agreement is intended to, or shall be deemed to, establish any partnership or
          joint venture between any of the parties, constitute any party the agent of another party, or authorize
          any party to make or enter into any commitments for or on behalf of any other party.<br />
          18.2. Each party confirms it is acting on its own behalf and not for the benefit of any other person.<br />
          <span>19. Further assurance</span><br />
          Each party shall, and shall use all reasonable endeavors to procure that any necessary
          third party shall, execute and deliver such documents and perform such acts as may be required
          for the purpose of giving full effect to this agreement.<br />
          <span>20. Notices</span><br />
          20.1. Any notice given to a party under or in connection with this agreement shall be in writing and shall be:<br />
              (a) delivered by hand or by pre-paid first-class post or other next working day delivery service at its
              registered office (if a company) or its principal place of business (in any other case); or 
              (b) sent by email to the following addresses (or an address substituted in writing by the party to be served):<br />
          20.2. Any notice shall be deemed to have been received:<br />
              (a) if delivered by hand, at the time the notice is left at the proper address;
              (b) if sent by pre-paid first class post or other next working day delivery service, at 9.00 am] on the second Business Day after posting; or
              (c) if sent by email, at the time of transmission, or, if this time falls outside Business Hours in
              the place of receipt, when Business Hours resume.<br />
          20.3. This clause does not apply to the service of any proceedings or other documents in any legal action or,
          where applicable, any arbitration or other method of dispute resolution. 
        </p>
      </div>

      <div class="app__terms--left__container" id="privacy_interest">
        <h4>Interest & fees</h4>

        <p>
          <span>21. Waiver</span><br />
          21.1. A waiver of any right or remedy is only effective if given in writing.<br />
          21.2. A delay or failure to exercise, or the single or partial exercise of, any right or remedy
          shall not waive that or any other right or remedy, nor shall it prevent or restrict the further
          exercise of that or any other right or remedy.<br />
          <span>22. Rights and remedies</span><br />
          Except as expressly provided in this agreement, the rights and remedies provided under this
          agreement are in addition to, and not exclusive of, any rights or remedies provided by law.<br />
          <span>23. Severance</span><br />
          23.1. If any provision or part-provision of this agreement is or becomes invalid, illegal or
          unenforceable, it shall be deemed deleted, but that shall not affect the validity and
          enforceability of the rest of this agreement.<br />
          23.2. If any provision or part-provision of this agreement is deemed deleted under clause 25.1
          the parties shall negotiate in good faith to agree a replacement provision that, to the greatest
          extent possible, achieves the intended commercial result of the original provision.<br />
          <span>24. Governing law</span><br />
          This agreement and any dispute or claim (including non-contractual disputes or claims) arising
          out of or in connection with it or its subject matter or formation shall be governed by and
          construed in accordance with the laws of the Federal Republic of Nigeria<br />
          <span>25. Jurisdiction</span><br />
          Each party irrevocably agrees that the courts of Lagos, Nigeria shall have exclusive jurisdiction to settle any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this agreement or its subject matter or formation.
        </p>
      </div>
    </div>
  </section>
</template>
