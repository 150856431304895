<template>
  <div
    id="myModal"
    class="app__modal"
    :style="
      {
        display: props.modalDisplay,
        top: props.modalDisplay == 'block' ? 0 : '-100%',
        opacity: props.modalDisplay == 'block' ? 1 : 0
      }
    "
  >
    <div class="app__modal--content">
      <div class="app__modal--content__header">
        <div class="app__modal--content__header--container">
          <div class="app__modal--content__header--container__close">
            <span @click="closeModal">&times;</span>
          </div>

          <div class="app__modal--content__header--container__header">
            <h2>{{ product?.productName }}</h2>

            <p>{{ product?.specification }}</p>

            <div class="app__modal--content__header--container__header--desc">
              <p>{{ product.description }}</p>
            </div>

            <div class="app__modal--content__header--container__header--badge" v-if="product">
              <span
                v-for="(item, index) in product?.items"
                :key="index"
              >
                {{ item }}
              </span>
            </div>
          </div>

          <div class="app__modal--content__header--container__content">
            <div class="app__modal--content__header--container__content--card">
              <h3>
                <span>
                  ₦{{ getProductPrice(product?.repaymentAmount) }}
                </span>&ensp;monthly
              </h3>
              <p>₦{{ getProductPrice(product?.initialPayment) }} down payment</p>
            </div>

            <p class="app__modal--content__header--container__content--separator">OR</p>

            <div class="app__modal--content__header--container__content--card">
              <h2>₦{{ getProductPrice(product?.totalPrice) }}</h2>
              <p>outright payment</p>
            </div>
          </div>

          <div class="app__modal--content__header--container__hardware">
            <hr /> 
            <h4>Hardware Specs</h4>
          </div>
        </div>
      </div>

      <div class="app__modal--content__body">
        <div class="app__modal--content__body--table">
          <!-- <div class="app__products--modal__table--hardware">
            <h4>Hardware Specs</h4>
          </div> -->

          <div class="app__modal--content__body--table__header">
            <h4>#</h4>
            <h4>Hardware</h4>
            <h4>Quantity</h4>
          </div>

          <div
            :class="
              index % 2 == 0
                ? 'app__modal--content__body--table__content border_column'
                : 'app__modal--content__body--table__content'
            "
            v-for="(component, index) in product?.components"
            :key="index"
          >
            <p>{{ index + 1 }}</p>
            <p>{{ component.specification }}</p>
            <p>{{ component.quantity }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useFormatFiguresToCurrency } from "@/composables/currencyFormater";
import { ref } from "vue";

/* eslint-disable  no-unused-vars */
/* eslint-disable  no-undef */
const props = defineProps({
  product: { type: Object, required: true },
  modalDisplay: { type: String, required: true, default: "none" }
});
const emits = defineEmits(["close"]);

const content = ref("Some text in the Modal.. !!");

const closeModal = () => emits("close");

const getProductPrice = (price) => {
    const { formattedCurrency } = useFormatFiguresToCurrency(price);

    return formattedCurrency.value;
  }
</script>