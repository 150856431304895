<template>
  <section class="app__terms">
    <div class="app__terms--right">
      <a
        class="app__legal--info__left--btn active-menu"
        href="#loan_introduction"
        id="introductions"
      >
        Introduction
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#loan_acceptance"
        id="acceptances"
      >
        Acceptance of terms
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#loan_grants"
        id="grant"
      >
        Grants & scope of license
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#loan_license"
        id="licenses"
      >
        License restriction
      </a>

      <a
        class="app__legal--info__left--btn"
        href="#loan_restriction"
        id="restrictions"
      >
        Acceptable use of restriction
      </a>
    </div>

    <div class="app__terms--left">
      <div class="app__terms--left__container" id="loan_introduction">
        <h4>Introduction</h4>

        <p>
          <span>TERMS AND CONDITIONS OF HIRE-PURCHASE AGREEMENT</span><br />

          These Terms & Conditions (the “Agreement”) shall form the basis of the hire-purchase arrangement provided by
          Tech-Partners (the “Lessor).  In this Agreement, “Lessee” refers to the party that accepts this Agreement. 
        </p>
      </div>

      <div class="app__terms--left__container" id="loan_acceptance">
        <h4>Acceptance of terms</h4>

        <p>
          <span>2. Asset hire</span><br />
          2.1. The Lessor shall lease the Asset to the Lessee subject to the terms and conditions of this agreement.<br />
          2.2. The Lessor shall not, other than in the exercise of its rights under this agreement or applicable law,
          interfere with the Lessee's quiet possession of the Asset.<br />
          <span>3. Rental Period</span><br />
          The Rental Period starts on the Commencement Date and shall continue for the Lease Period unless
          and until this agreement is terminated earlier in accordance with its terms.<br />
          <span>4. Rental Payments and Deposit</span><br />
          4.1. The Lessee shall pay the Rental Payments to the Lessor in accordance with the Payment Schedule.
          The Rental Payments shall be paid in Nigerian Naira and shall be made in cleared funds to the bank account
          nominated by the Lessor.<br />
          4.2. The Rental Payments are exclusive of VAT and any other applicable taxes and duties or similar
          charges which shall be payable by the Lessee at the rate and in the manner from time to time prescribed by law.<br />
          4.3. All amounts due under this agreement shall be paid in full without any set-off, counterclaim,
          deduction or withholding (other than any deduction or withholding of tax as required by law).<br />
          4.4. If the Lessee fails to make a payment due to the Lessor under this agreement by the due date, then,
          without limiting the Lessor's remedies under clause 11, the Lessee shall pay interest on the overdue sum
          from the due date until payment of the overdue sum, whether before or after judgment. Interest under this
          clause will accrue each day at 4% a year above the Central Bank of Nigeria’s monetary policy rate from time to time.<br />
          4.5. The Deposit is a deposit against default by the Lessee of payment of any Rental Payments or any loss
          of or damage caused to the Asset. The Lessee shall, on the date of this agreement, pay the Deposit to the Lessor.
          If the Lessee fails to make any Rental Payments in accordance with the Payment Schedule, or causes any loss or
          damage to the Asset (in whole or in part), the Lessor shall be entitled to apply the Deposit against such default,
          loss or damage. The Lessee shall pay to the Lessor any sums deducted from the Deposit within ten Business Days of a
          demand for the same. The Deposit (or balance of the Deposit) shall be refundable within ten (10) Business Days of the
          end of the Rental Period.<br />
          <span>5. Delivery and installation</span><br />
          5.1. Delivery shall be made by the Lessor. The Lessor shall use all reasonable endeavors to
          effect Delivery by the Delivery Date. Risk shall transfer in accordance with clause 6.2 of this agreement.<br />
          5.2. The Lessor shall at the Lessee's expense install the Asset at the Site. The Lessee shall procure that a duly
          authorized representative of the Lessee shall be present at the installation of the Asset. Acceptance by such
          representative of installation shall constitute conclusive evidence that the Lessee has examined the Asset and
          has found it to be in good condition, complete and fit in every way for the purpose for which it is intended
          (save as regards any latent defects not reasonably apparent on inspection). If required by the Lessor,
          the Lessee's duly authorized representative shall sign a receipt confirming such acceptance.<br />
          5.3. To facilitate Delivery and installation, the Lessee shall at its sole expense provide all requisite
          materials, facilities, access and suitable working conditions to enable Delivery and installation to be
          carried out safely and expeditiously.<br />
          5.4. If the Lessee fails to accept delivery of the Asset on the Delivery Date, then, except where such failure is 
          caused by the Lessor's failure to comply with its obligations under this agreement:<br />
            (a) the Asset shall be deemed to have been delivered at 9.00 am on the Delivery Date; and
            (b) the Lessor shall store the Asset until delivery takes place, and charge the Lessee for
            all related costs and expenses (including insurance).
        </p>
      </div>

      <div class="app__terms--left__container" id="loan_grants">
        <h4>Grants & scope of license</h4>

        <p>
          <span>6. Title, risk and insurance</span><br />
          6.1. The Asset shall at all times remain the property of the Lessor, and the Lessee shall have no right,
          title or interest in or to the Asset (save the right to possession and use of the Asset subject to the
          terms and conditions of this agreement) except where the Lessee purchases the Asset pursuant to the Purchase
          Option in clause 8. <br />
          6.2. The risk of loss, theft, damage or destruction of the Asset shall pass to the Lessee on Delivery.
          The Asset shall remain at the sole risk of the Lessee during the Rental Period and any further term
          during which the Asset is in the possession, custody or control of the Lessee (Risk Period) until such
          time as the Asset is redelivered to the Lessor. During the Rental Period and the Risk Period, the Lessee
          shall, at its own expense, obtain and maintain the following insurances:<br />
            (a) insurance of the Asset to a value not less than its full replacement value comprehensively against all
            usual risks of loss, damage or destruction by fire, theft or accident, and such other risks as the Lessor
            may from time to time nominate in writing;
            (b) insurance for such amounts as a prudent owner or operator of the Asset would insure for, or such amount
            as the Lessor may from time to time reasonably require, to cover any third party or public liability risks
            of whatever nature and however arising in connection with the Asset; and
            (c) insurance against such other or further risks relating to the Asset as may be required by law, together
            with such other insurance as the Lessor may from time to time consider reasonably necessary and advise to the
            Lessee in writing.<br />
          6.3. All insurance policies procured by the Lessee shall be endorsed to provide the Lessor with at least
          fourteen (14) Business Days' prior written notice of cancellation or material change (including any
          reduction in coverage or policy amount) and shall on the Lessor's request name the Lessor on the policies
          as a loss payee in relation to any claim relating to the Asset. The Lessee shall be responsible for
          paying any deductibles due on any claims under such insurance policies.<br />
          6.4. If the Lessee fails to effect or maintain any of the insurances required under this agreement, the
          Lessor shall be entitled to effect and maintain the same, pay such premiums as may be necessary for
          that purpose and recover the same as a debt due from the Lessee.<br />
          6.5. The Lessee shall, on demand, supply copies of the relevant insurance policies or other
          insurance confirmation acceptable to the Lessor and proof of premium payment to the Lessor to
          confirm the insurance arrangements.<br />
          6.6. The Lessee shall give immediate written notice to the Lessor in the event of any loss,
          accident or damage to the Asset arising out of or in connection with the Lessee's possession or use of the Asset.<br />
          <span>7. Lessee's responsibilities</span><br />
          7.1. The Lessee shall during the term of this agreement:<br />
              (a) ensure that the Asset is kept and operated in a suitable environment used only for the purposes for which it is designed, and operated in a proper manner in accordance with any operating instructions provided by the Lessor;
              (b) take such steps (including compliance with all safety and usage instructions provided by the Lessor) as may be necessary to ensure, so far as is reasonably practicable, that the Asset is at all times safe and without risk to health when it is being set, used, cleaned or maintained by a person at work;
              (c) maintain at its own expense the Asset in good and substantial repair in order to keep it in as good an operating condition as it was on the Delivery Date (fair wear and tear only excepted) including replacement of worn, damaged and lost parts, and shall make good any damage to the Asset;
              (d) make no alteration to the Asset and shall not remove any existing component (or components) from the Asset [without the prior written consent of the Lessor unless the component (or components) is (or are) replaced immediately (or if removed in the ordinary course of repair and maintenance as soon as practicable) by the same component or by one of a similar make and model or an improved or advanced version of it. Title in all substitutions, replacements, renewals made in or to the Asset shall vest in the Lessor immediately on installation;
              (e) keep the Lessor fully informed of all material matters relating to the Asset;
              (f) keep the Asset at all times at the Site and shall not move or attempt to move any part of the Asset to any other location without the Lessor's prior written consent;
              (g) permit the Lessor or its duly authorized representative to inspect the Asset at all reasonable times and for such purpose to enter on the Site or any premises at which the Asset may be located, and shall grant reasonable access and facilities for such inspection;
              (h) maintain operating and maintenance records of the Asset and make copies of such records readily available to the Lessor, together with such additional information as the Lessor may reasonably require;
              (i) not, without the prior written consent of the Lessor, part with control of (including for the purposes of repair or maintenance), sell or offer for sale, underlet or lend the Asset or allow the creation of any mortgage, charge, lien or other security interest in respect of it;
              (j) not without the prior written consent of the Lessor, attach the Asset to any land or building so as to cause the Asset to become a permanent or immovable fixture on such land or building. If the Asset does become affixed to any land or building then the Asset must be capable of being removed without material injury to such land or building and the Lessee shall repair and make good any damage caused by the affixation or removal of the Asset from any land or building and indemnify the Lessor against all losses, costs or expenses incurred as a result of such affixation or removal;
              (k) not do or permit to be done any act or thing which will or may jeopardise the right, title or interest of the Lessor in the Asset and, where the Asset has become affixed to any land or building, the Lessee must take all necessary steps to ensure that the Lessor may enter such land or building and recover the Asset both during the term of this agreement and for a reasonable period thereafter, including by procuring from any person having an interest in such land or building, a waiver in writing and in favour of the Lessor of any rights such person may have or acquire in the Asset and a right for the Lessor to enter onto such land or building to remove the Asset;
              (l) not suffer or permit the Asset to be confiscated, seized or taken out of its possession or control under any distress, execution or other legal process, but if the Asset is so confiscated, seized or taken, the Lessee shall notify the Lessor and the Lessee shall at its sole expense use its best endeavours to procure an immediate release of the Asset and shall indemnify the Lessor on demand against all losses, costs, charges, damages and expenses [reasonably] incurred as a result of such confiscation;
              (m) not use the Asset for any unlawful purpose;
              (n) ensure that at all times the Asset remains identifiable as being the Lessor's property and wherever possible shall ensure that a visible sign to that effect is attached to the Asset;
              (o) deliver up the Asset at the end of the Rental Period at such address as the Lessor requires, or if necessary allow the Lessor or its representatives access to the Site or any premises where the Asset is located for the purpose of removing the Asset; and
              (p) not do or permit to be done anything which could invalidate the insurances referred to in clause 6.2.<br />
          7.2. The Lessee acknowledges that the Lessor shall not be responsible for any loss of or damage to the Asset arising
          out of or in connection with any negligence, misuse, mishandling of the Asset or otherwise caused by the Lessee or
          its officers, employees, agents and contractors and the Lessee shall indemnify the Lessor in full against all
          liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss
          of profit, loss of reputation and all interest, penalties and legal costs (calculated on a full indemnity basis)
          and all other professional costs and expenses) suffered or incurred by the Lessor arising out of, or in connection
          with any failure by the Lessee to comply with its obligations in this clause 7.<br />
          <span>8. Purchase Option</span><br />
          8.1. The Lessee shall, subject to clause 8.2, have the option, exercisable by not less than twenty
          (20) Business Days' written notice to the Lessor, to purchase the Asset on the last Business Day
          of the Rental Period at the Purchase Option Price.<br />
          8.2. The Purchase Option may be exercised only if:<br />
              (a) all amounts due to the Lessor under this agreement up to the date of exercise of the Purchase Option have been paid in full by the Lessee; and
              (b) the Rental Period has not ended by reason of the Lessor terminating this agreement in accordance with clause 11.1.<br />
          8.3. On completion of the purchase of the Asset under this clause 8, such title to the Asset as the Lessor had 
          on the Commencement Date shall transfer to the Lessee. The Asset shall transfer to the Lessee in the 
          condition and at the location in which it is found on the date of transfer.<br />
          <span>9. Warranty</span><br />
          9.1. The Lessor warrants that the Asset shall substantially conform to its specification (as made available by the Lessor),
          be of satisfactory quality and fit for any purpose held out by the Lessor. The Lessor shall use all reasonable endeavors
          to remedy, free of charge, any material defect in the Asset which manifests itself within the stipulated period on the
          quotation from Delivery, provided that:<br />
              (a) the Lessee notifies the Lessor of any defect in writing within or through the platform provided ten (10) Business Days of the defect occurring;
              (b) the Lessor is permitted to make a full examination of the alleged defect;
              (c) the defect did not materialise as a result of misuse, neglect, alteration, mishandling or unauthorised manipulation by any person other than the Lessor's authorised personnel;
              (d) the defect did not arise out of any information, design or any other assistance supplied or furnished by the Lessee or on its behalf; and
              (e) the defect is directly attributable to defective material, workmanship or design.<br />
          9.2. Insofar as the Asset comprises or contains Asset or components which were not manufactured or produced by the Lessor, 
          the Lessee shall be entitled only to such warranty or other benefit as the Lessor has received from the manufacturer.<br />
          9.3. If the Lessor fails to remedy any material defect in the Asset in accordance with clause 9.1, the Lessor
          shall, at the Lessee's request, accept the return of part or all of the Asset and make an appropriate reduction
          to the Rental Payments payable during the remaining term of the agreement and, if relevant, return any Deposit
          (or any part of it).<br />
        </p>
      </div>

      <div class="app__terms--left__container" id="loan_license">
        <h4>License restriction</h4>

        <p>
          <span>10. Limitation of liability</span><br />
          10.1. The limits and exclusions in this clause reflect the insurance cover the Lessor has been able to
          arrange and the Lessee is responsible for making its own arrangements for the insurance of any excess liability.<br />
          10.2. References to liability in this clause 10 include every kind of liability arising under or in connection
          with this agreement including liability in contract, tort (including negligence), misrepresentation,
          restitution or otherwise.<br />
          10.3. Nothing in this agreement limits any liability which cannot legally be limited including liability for:<br />
              (a) death or personal injury caused by negligence; and
              (b) fraud or fraudulent misrepresentation.<br />
          10.4. Subject to clause 10.3, the Lessor's total liability to the Lessee shall not exceed N1,000,000 (One Million Naira).<br />
          10.5. Subject to clause 10.3, the Lessor shall not be liable under this agreement for any:<br />
              (a) loss of profits;
              (b) loss of sales or business;
              (c) loss of agreements or contracts;
              (d) loss of anticipated savings;
              (e) loss of use or corruption of software, data or information;
              (f) loss of or damage to goodwill; and
              (g) indirect or consequential loss.<br />
          10.6. Subject to clause 10.3, all implied terms and conditions as to the quality or performance of the Asset
          and any other goods or services provided under this agreement are, to the fullest extent permitted by law,
          excluded from this agreement.<br />
          <span>11. Termination</span><br />
          11.1. Without affecting any other right or remedy available to it, the Lessor may terminate this agreement with
          immediate effect by giving written notice to the Lessee if:<br />
              (a) the Lessee fails to pay any amount due under this agreement on the due date for payment;
              (b) the Lessee commits a breach of any other term of this agreement which breach is irremediable or (if such breach is remediable) fails to remedy that breach within a period of seven (7) days after being notified [in writing] to do so;
              (c) the Lessee repeatedly breaches any of the terms of this agreement in such a manner as to reasonably justify the opinion that its conduct is inconsistent with it having the intention or ability to give effect to the terms of this agreement;
              (d) the Lessee suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they fall due or admits inability to pay its debts;
              (e) the Lessee commences negotiations with all or any class of its creditors with a view to rescheduling any of its debts, or makes a proposal for or enters into any compromise or arrangement with its creditors;
              (f) a petition is filed, a notice is given, a resolution is passed, or an order is made, for or in connection with the winding up of the Lessee (being a company, limited liability partnership or partnership);
              (g) an application is made to court, or an order is made, for the appointment of an administrator, or a notice of intention to appoint an administrator is given or an administrator is appointed, over the Lessee (being a company);
              (h) the holder of a qualifying floating charge over the assets of the Lessee (being a company) has become entitled to appoint or has appointed an administrative receiver;
              (i) a person becomes entitled to appoint a receiver over all or any of the assets of the Lessee or a receiver is appointed over all or any of the assets of the Lessee;
              (j) a creditor or encumbrancer of the Lessee attaches or takes possession of, or a distress, execution, sequestration or other such process is levied or enforced on or sued against, the whole or any part of the Lessee's assets and such attachment or process is not discharged within fourteen (14) days;
              (k) any event occurs, or proceeding is taken, with respect to the Lessee in any jurisdiction to which it is subject that has an effect equivalent or similar to any of the events mentioned in clause 11.1(d) to clause 11.1(k) (inclusive);
              (l) the Lessee's financial position deteriorates so far as to reasonably justify the opinion that its ability to give effect to the terms of this agreement is in jeopardy;
              (m) the Lessee suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of its business;
              (n) there is a change of control of the Lessee.<br />
          11.2. This agreement shall automatically terminate if a Total Loss occurs in relation to the Asset.<br />
          <span>12. Consequences of termination or expiry</span><br />
          12.1. On expiry or termination of this agreement, however caused:<br />
              (a) the Lessor's consent to the Lessee's possession of the Asset shall terminate;
              (b) the Lessor may, by its authorized representatives, without notice and at the Lessee's expense, retake possession of the Asset and for this purpose may enter the Site or any premises at which the Asset is located; 
              (c) the Lessee shall ensure the safe and proper storage of the Asset until it has been collected by the Lessor; and
              (d) without prejudice to any other rights or remedies of the Lessee, the Lessee shall pay to the Lessor on demand:
                  (i) all Rental Payments and other sums due but unpaid at the date of such demand together with any interest accrued pursuant to clause 4.4; and
                  (ii) any costs and expenses incurred by the Lessor in recovering the Asset or in collecting any
                  sums due under this agreement (including any storage, insurance, repair, transport, legal and remarketing costs).<br />
          12.2. On termination of this agreement pursuant to clause 11.1, any other repudiation of this agreement by the
          Lessee which is accepted by the Lessor or pursuant to clause 11.3, without prejudice to any other rights or
          remedies of the Lessor, the Lessee shall pay to the Lessor on demand a sum equal to the whole of the Rental
          Payments that would (but for the termination) have been payable if the agreement had continued from the date
          of such demand to the end of the Rental Period, less:<br />
              (a) a discount for accelerated payment at the percentage rate set out in the Payment Schedule; and
              (b) the Lessor's reasonable assessment of the market value of the Asset on sale.<br />
          12.3. The sums payable pursuant to clause 12.2 shall be agreed compensation for the Lessor's loss and shall
          be payable in addition to the sums payable pursuant to clause 12.1(d). Such sums may be partly or wholly
          recovered from any Deposit.<br />
          12.4. Any provision of this agreement that expressly or by implication is intended to come into or continue
          in force on or after termination or expiry of this agreement shall remain in full force and effect.<br />
          12.5. Termination or expiry of this agreement shall not affect any rights, remedies, obligations or liabilities
          of the parties that have accrued up to the date of termination or expiry, including the right to claim damages
          in respect of any breach of the agreement which existed at or before the date of termination or expiry.<br />
          <span>13. Force majeure</span><br />
          Neither party shall be in breach of this agreement or otherwise liable for any failure or delay in the performance
          of its obligations if such delay or failure results from events, circumstances or causes beyond its reasonable control.
          The time for performance of such obligations shall be extended accordingly. If the period of delay or non-performance
          continues for sixty (60) days, the party not affected may terminate this agreement by giving fourteen (14) written notice
          to the affected party.<br />
          <span>14. Confidential information</span><br />
          14.1. Each party undertakes that it shall not at any time during this agreement, and for a period of two (2)
          years after termination or expiry of this agreement, disclose to any person any confidential information
          concerning the business, assets, affairs, customers, clients or suppliers of the other party, except as
          permitted by clause 14.2.<br />
          14.2. Each party may disclose the other party's confidential information:<br />
              (a) to its employees, officers, representatives, contractors, subcontractors or advisers who need to know such information for the purposes of exercising the party's rights or carrying out its obligations under or in connection with this agreement. Each party shall ensure that its employees, officers, representatives, contractors, subcontractors or advisers to whom it discloses the other party's confidential information comply with clause 14; and
              (b) as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.<br />
          14.3. Neither party shall use the other party's confidential information for any purpose other than to exercise
          its rights and perform its obligations under or in connection with this agreement.<br />
          <span>15. Assignment and other dealings</span><br />
          This Lessee shall not assign, transfer, mortgage, charge, subcontract, delegate, declare a trust over or
          deal in any other manner with any of its rights and obligations under this agreement. The Lessor may assign
          its rights or obligations under this Agreement.
        </p>
      </div>

      <div class="app__terms--left__container" id="loan_restriction">
        <h4>Acceptable use of restriction</h4>

        <p>
          <span>16. Entire agreement</span><br />
          16.1. This agreement constitutes the entire agreement between the parties.<br />
          16.2. Each party acknowledges that in entering into this agreement it does not rely on any statement,
          representation, assurance or warranty (whether made innocently or negligently) that is not set out in
          this agreement. Each party agrees that it shall have no claim for innocent or negligent misrepresentation
          based on any statement in this agreement.<br />
          <span>17. Variation</span><br />
          The Lessor may vary the terms of this Agreement with notice to the Lessee.<br />
          <span>18. No partnership or agency</span><br />
          18.1. Nothing in this agreement is intended to, or shall be deemed to, establish any partnership or
          joint venture between any of the parties, constitute any party the agent of another party, or authorize
          any party to make or enter into any commitments for or on behalf of any other party.<br />
          18.2. Each party confirms it is acting on its own behalf and not for the benefit of any other person.<br />
          <span>19. Further assurance</span><br />
          Each party shall, and shall use all reasonable endeavors to procure that any necessary
          third party shall, execute and deliver such documents and perform such acts as may be required
          for the purpose of giving full effect to this agreement.<br />
          <span>20. Notices</span><br />
          20.1. Any notice given to a party under or in connection with this agreement shall be in writing and shall be:<br />
              (a) delivered by hand or by pre-paid first-class post or other next working day delivery service at its
              registered office (if a company) or its principal place of business (in any other case); or 
              (b) sent by email to the following addresses (or an address substituted in writing by the party to be served):<br />
          20.2. Any notice shall be deemed to have been received:<br />
              (a) if delivered by hand, at the time the notice is left at the proper address;
              (b) if sent by pre-paid first class post or other next working day delivery service, at 9.00 am] on the second Business Day after posting; or
              (c) if sent by email, at the time of transmission, or, if this time falls outside Business Hours in
              the place of receipt, when Business Hours resume.<br />
          20.3. This clause does not apply to the service of any proceedings or other documents in any legal action or,
          where applicable, any arbitration or other method of dispute resolution. 
        </p>
      </div>
    </div>
  </section>
</template>