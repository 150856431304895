import "@/styles/style.scss";
import 'aos/dist/aos.css';

import AOS from 'aos';
import App from './App.vue'
import VueAxios from 'vue-axios';
import axios from 'axios';
import { createApp } from 'vue'
import router from './router'

AOS.init();

createApp(App)
  .use(router)
  .use(VueAxios, axios)
  .mount('#app')
