<template>
  <nav class="app__navbar">
    <router-link to="/">
      <div class="app__navbar--logo">
        <img src="@/assets/mobile_logo.svg" alt="logo" class="mobile" />
        <img src="@/assets/logo.svg" alt="logo" class="large" />
      </div>
    </router-link>
    <div class="app__navbar--login">
      <a
        class="app__navbar--login__sign"
        href="https://app.powernow.io/#/login"
        target="_blank"
      >
        Login
      </a>
      <button @click="goToApp">Get Started</button>
    </div>
  </nav>
</template>

<script>
export default {
  setup() {
    const goToApp = () => {
      const anchor = document.createElement('a');
      anchor.href = "https://app.powernow.io/";
      anchor.target = "_blank";
      anchor.click();
    }

    return {
      goToApp,
    }
  }
}
</script>