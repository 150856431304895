<template>
  <component :is="currentLayoutComponent">
    <template
      v-for="slotName in Object.keys($slots)"
      :key="slotName"
      v-slot:[slotName]="slotProps"
    >
      <slot :name="slotName" v-bind="slotProps" />
    </template>
  </component>
</template>

<script>
import StandardLayout from '@/components/StandardLayout.vue';
import TermsConditionsLayout from '@/components/TermsConditionsLayout.vue';
import { useRouter } from "vue-router";
import { computed } from 'vue';

const layoutComponents = {
  standard: StandardLayout,
  terms: TermsConditionsLayout,
}

export default {
  setup () {
    const router = useRouter();
    
    const currentLayoutComponent = computed(() => {
      const layout = router?.currentRoute.value?.meta?.layout || "standard";
      return layoutComponents[layout];
    })

    return {
      currentLayoutComponent
    }
  }
}
</script>
